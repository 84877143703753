export const LOADING_STATE = {
  NEVER: "NEVER",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export const ROLES = {
  ADMIN: 10000,
  USER: 1,
};

import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import RegistrationForm from "../components/RegistrationForm";

const useStyles = makeStyles(() => ({
  text: {
    height: "20%",
  },
  container: {
    border: "1px solid black",
    borderRadius: "5px",
    width: "300px",
    height: "400px",
    margin: "50px auto",
    textAlign: "center",
  },
}));

const Registration = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text} variant="h5">
        Регистрация
      </Typography>
      <NavLink to={"/login"}>Войти</NavLink>
      <RegistrationForm />
    </div>
  );
};

Registration.propTypes = {
  setUser: PropTypes.func,
};

export default Registration;

import DataRow from "./DataRow";
import PropTypes from "prop-types";

const KPSList = ({ kps }) => {
  return kps.map((kp) => (
    <DataRow
      key={kp._id}
      date={kp.date}
      receiverName={kp.name}
      receiverEmail={kp.email}
      mailType={kp.type}
      read={kp.isRead}
    />
  ));
};

KPSList.propTypes = {
  kps: PropTypes.array,
};

export default KPSList;

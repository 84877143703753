import React from "react";
import PropTypes from "prop-types";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";

import KPSList from "../components/KPSList";
import SendKPForm from "../components/SendKPForm";
import UserBox from "../components/UserBox";
import DataRow from "../components/DataRow";
import kpApi from "../services/api/kp";
import { LOADING_STATE } from "../constants";

const useStyles = makeStyles(() => ({
  container: {
    height: "500px",
    overflow: "hidden",
  },
  userBox: {
    marginBottom: "25px",
  },
  kpListContainer: {
    border: "1px solid #c9c9ff",
    borderRadius: "5px",
    height: 600,
    overflow: "auto",
  },
  kpMainRow: {
    marginTop: "25px",
  },
  linkBox: {
    textAlign: "center",
  },
}));

const RootView = ({ user, setUser }) => {
  const classes = useStyles();

  const [kps, setKps] = React.useState([]);
  const [loadingStateKps, setLoadingStateKps] = React.useState(
    LOADING_STATE.NEVER
  );

  React.useEffect(() => {
    setLoadingStateKps(LOADING_STATE.LOADING);
    kpApi
      .getAll()
      .then((res) => {
        setKps(res);
        setLoadingStateKps(LOADING_STATE.SUCCESS);
      })
      .catch((e) => setLoadingStateKps(LOADING_STATE.ERROR));
  }, []);

  const setNewKps = (kp) => {
    setKps((prev) => [kp, ...prev]);
  };

  return (
    <div>
      <Grid
        container
        direction="row-reverse"
        className={classes.userBox}
        spacing={4}
      >
        <UserBox user={user} setUser={setUser} />
        <Grid item md={1} />
        <SendKPForm setKps={setNewKps} />
      </Grid>
      <div className={classes.kpMainRow}>
        <DataRow isMain />
      </div>
      <div className={classes.kpListContainer}>
        {loadingStateKps === LOADING_STATE.LOADING ? (
          <CircularProgress size={50} />
        ) : (
          <KPSList kps={kps} />
        )}
      </div>
    </div>
  );
};

RootView.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
};

export default RootView;

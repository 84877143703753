import useAuth from "./hooks/useAuth";
import { LOADING_STATE } from "./constants";
import { CentralCircularProgress } from "./components/CentralCircularProgress";
import { Redirect, Route, Switch } from "react-router";
import Login from "./views/Login";
import Registration from "./views/Registration";
import { Box } from "@material-ui/core";
import RootView from "./views/RootView";
import { ROLES } from "./constants";

const App = () => {
  const { loadingState, user, setUser } = useAuth();

  if (!user && loadingState === LOADING_STATE.LOADING)
    return <CentralCircularProgress size={100} />;

  return (
    <Box sx={{ width: "100%", height: "100vh" }}>
      <Switch>
        <Route exact path="/login" render={() => <Login setUser={setUser} />}>
          {user && <Redirect to="/" />}
        </Route>
        <Route exact path="/registration" render={() => <Registration />}>
          {(!user || user.role !== ROLES.ADMIN) && <Redirect to="/" />}
        </Route>
        <Route
          path="/"
          render={() => <RootView user={user} setUser={setUser} />}
        >
          {!user && <Redirect to="/login" />}
        </Route>
      </Switch>
    </Box>
  );
};

export default App;

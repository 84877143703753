import { Grid, Typography, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { parseISO, format } from "date-fns";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  text: {
    wordBreak: "break-word",
  },
  container: {
    border: "1px solid #e6e6e6",
    textAlign: "center",
  },
}));

const DataRow = ({
  isMain,
  date,
  receiverName,
  receiverEmail,
  mailType,
  read,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.container}
      wrap="nowrap"
      justifyContent="space-around"
    >
      <Grid item xs={2}>
        <Typography className={classes.text}>
          {isMain ? "Дата" : format(parseISO(date), "M/d/yyyy h:mm:s aa")}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.text}>
          {isMain ? "Имя получателя" : receiverName}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography className={classes.text}>
          {isMain ? "Email получателя" : receiverEmail}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className={classes.text}>
          {isMain ? "Шаблон" : mailType}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        {isMain ? (
          <Typography>Прочитано</Typography>
        ) : read ? (
          <CheckIcon />
        ) : (
          <ClearIcon />
        )}
      </Grid>
    </Grid>
  );
};

DataRow.propTypes = {
  isMain: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  receiverName: PropTypes.string,
  receiverEmail: PropTypes.string,
  mailType: PropTypes.string,
  read: PropTypes.bool,
};

export default DataRow;

import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import authApi from "../services/api/auth";
import { LOADING_STATE } from "../constants";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  form: {
    height: "70%",
  },
  text: {
    height: "20%",
  },
  container: {
    border: "1px solid black",
    borderRadius: "5px",
    width: "300px",
    height: "300px",
    margin: "50px auto",
    textAlign: "center",
  },
  grid: {
    height: "100%",
    width: "80%",
    margin: "10px auto",
  },
}));

const LoginForm = ({ setUser }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [error, setError] = React.useState("");
  const [loginLoadingState, setLoginLoadingState] = React.useState(
    LOADING_STATE.NEVER
  );

  const handleClose = () => {
    setError("");
  };

  const onSubmit = (data) => {
    setLoginLoadingState(LOADING_STATE.LOADING);
    authApi
      .login(data)
      .then((res) => setUser(res))
      .catch((e) => setError("Ошибка входа"))
      .finally(() => setLoginLoadingState(LOADING_STATE.NEVER));
  };

  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          className={classes.grid}
          direction="column"
          justifyContent="space-around"
          wrap="nowrap"
        >
          <Controller
            name="email"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="email-login-outlined-basic"
                size="small"
                type="email"
                variant="outlined"
                label="Почта"
                error={errors.email && errors.email.type === "required"}
                helperText={
                  errors.email &&
                  errors.email.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Пароль"
                id="pass-login-outlined-basic"
                size="small"
                variant="outlined"
                type="password"
                fullWidth
                error={errors.password && errors.password.type === "required"}
                helperText={
                  errors.password &&
                  errors.password.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />

          <Button
            disabled={loginLoadingState === LOADING_STATE.LOADING}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Войти
          </Button>
        </Grid>
      </form>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleClose}
        message={error}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </React.Fragment>
  );
};

LoginForm.propTypes = {
  setUser: PropTypes.func,
};

export default LoginForm;

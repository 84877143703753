import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import usersApi from "../services/api/users";
import { LOADING_STATE } from "../constants";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  form: {
    height: "70%",
  },
  grid: {
    height: "100%",
    width: "80%",
    margin: "10px auto",
  },
}));

const RegistrationForm = () => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [status, setStatus] = React.useState("");
  const [loginRegistrationState, setRegistrationLoadingState] = React.useState(
    LOADING_STATE.NEVER
  );

  const handleClose = () => {
    setStatus("");
  };

  const onSubmit = (data) => {
    setRegistrationLoadingState(LOADING_STATE.LOADING);
    usersApi
      .registration(data)
      .then((res) => setStatus("Пользователь зарегистрирован"))
      .catch((e) => setStatus("Ошибка регистрации"))
      .finally(() => setRegistrationLoadingState(LOADING_STATE.NEVER));
  };

  return (
    <React.Fragment>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          className={classes.grid}
          direction="column"
          justifyContent="space-around"
          wrap="nowrap"
        >
          <Controller
            name="name"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                size="small"
                variant="outlined"
                id="name-outlined-basic"
                label="Имя"
                error={errors.name && errors.name.type === "required"}
                helperText={
                  errors.name &&
                  errors.name.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />
          <Controller
            name="surname"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="surname-outlined-basic"
                size="small"
                variant="outlined"
                label="Фамилия"
                error={errors.surname && errors.surname.type === "required"}
                helperText={
                  errors.surname &&
                  errors.surname.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="surname-outlined-basic"
                size="small"
                type="email"
                variant="outlined"
                label="Почта"
                error={errors.email && errors.email.type === "required"}
                helperText={
                  errors.email &&
                  errors.email.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Пароль"
                id="pass-outlined-basic"
                size="small"
                variant="outlined"
                type="password"
                fullWidth
                error={errors.password && errors.password.type === "required"}
                helperText={
                  errors.password &&
                  errors.password.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />

          <Button
            disabled={loginRegistrationState === LOADING_STATE.LOADING}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            Зарегистрироваться
          </Button>
        </Grid>
      </form>

      <Snackbar
        open={!!status}
        autoHideDuration={6000}
        onClose={handleClose}
        message={status}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </React.Fragment>
  );
};

RegistrationForm.propTypes = {
  setUser: PropTypes.func,
};

export default RegistrationForm;

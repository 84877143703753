import axios from "axios";

const BASE_API_URL = "/api/kp";
const instance = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
});

const kpApi = {
  async createKp({ template, to, displayName }) {
    const response = await instance.post("/create", {
      template,
      to,
      displayName,
    });
    return response.data;
  },
  async getAll() {
    const response = await instance.get("/getall");
    return response.data;
  },
};

export default kpApi;

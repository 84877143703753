import axios from "axios";
export let cancel;

const BASE_API_URL = "/auth";
const instance = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
});

const authApi = {
  async login({ email, password }) {
    const response = await instance.post("/login", {
      email,
      password,
    });
    return response.data;
  },
  async logout() {
    const response = await instance.post("/logout");
    return response.data;
  },
};

export default authApi;

import axios from "axios";

const BASE_API_URL = "/api/users";
const instance = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
});

const usersApi = {
  async getMe() {
    const response = await instance.get("/getme");
    return response.data;
  },
  async registration({ email, password, name, surname }) {
    const response = await instance.post("/registration", {
      email,
      password,
      name,
      surname,
    });
    return response.data;
  },
};

export default usersApi;

import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import LoginForm from "../components/LoginForm";

const useStyles = makeStyles(() => ({
  text: {
    height: "20%",
  },
  container: {
    border: "1px solid black",
    borderRadius: "5px",
    width: "300px",
    height: "300px",
    margin: "50px auto",
    textAlign: "center",
  },
}));

const Login = ({ setUser }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text} variant="h5">
        Войти
      </Typography>
      <LoginForm setUser={setUser} />
    </div>
  );
};

Login.propTypes = {
  setUser: PropTypes.func,
};

export default Login;

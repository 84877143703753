import React from "react";
import { LOADING_STATE } from "../constants";
import usersApi from "../services/api/users";

const useAuth = () => {
  const [loadingState, setLoadingState] = React.useState(LOADING_STATE.NEVER);

  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    setLoadingState(LOADING_STATE.LOADING);
    usersApi
      .getMe()
      .then((res) => {
        setUser(res);
        setLoadingState(LOADING_STATE.SUCCESS);
      })
      .catch((e) => {
        setLoadingState(LOADING_STATE.ERROR);
      });
  }, []);

  return { loadingState, user, setUser };
};

export default useAuth;

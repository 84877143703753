import React from "react";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

import kpApi from "../services/api/kp";
import { LOADING_STATE } from "../constants";

const SendKPForm = ({ setKps }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { template: "kp-usual" } });

  const [sendingKpState, setSendingKpState] = React.useState(
    LOADING_STATE.NEVER
  );

  const [error, setError] = React.useState("");

  const handleClose = () => {
    setError("");
  };

  const onSubmit = (data) => {
    setSendingKpState(LOADING_STATE.LOADING);
    kpApi
      .createKp(data)
      .then((res) => setKps(res))
      .catch((e) => setError("Ошибка отправки"))
      .finally(() => setSendingKpState(LOADING_STATE.NEVER));
  };

  return (
    <React.Fragment>
      <Grid
        container
        item
        md={7}
        xs={12}
        direction="row"
        spacing={4}
        alignItems="flex-end"
        justifyContent="space-around"
        component={"form"}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item md={3} xs={12}>
          <Controller
            name="template"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                size="small"
                fullWidth
                // variant="outlined"
                id="template-outlined-basic"
                label="Тип письма"
                error={errors.template && errors.template.type === "required"}
              >
                <MenuItem value={"kp-usual"}>Обычное КП</MenuItem>
              </Select>
            )}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <Controller
            name="displayName"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                id="displayName-outlined-basic"
                fullWidth
                // variant="outlined"
                size="small"
                label="Имя получателя"
                error={
                  errors.displayName && errors.displayName.type === "required"
                }
                helperText={
                  errors.displayName &&
                  errors.displayName.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <Controller
            name="to"
            control={control}
            defaultValue={""}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                id="to-outlined-basic"
                size="small"
                type="email"
                // variant="outlined"
                label="Почта получателя"
                error={errors.to && errors.to.type === "required"}
                helperText={
                  errors.to &&
                  errors.to.type === "required" &&
                  "Обязательно для заполнения"
                }
              />
            )}
          />
        </Grid>

        <Grid item md={1} xs={12}>
          <Button
            disabled={sendingKpState === LOADING_STATE.LOADING}
            type="submit"
            variant="contained"
            color="primary"
          >
            Отправить
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleClose}
        message={error}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </React.Fragment>
  );
};

SendKPForm.propTypes = {
  kps: PropTypes.func,
};

export default SendKPForm;

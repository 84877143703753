import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import authApi from "../services/api/auth";
import { ROLES, LOADING_STATE } from "../constants";

const UserBox = ({ user, setUser }) => {
  const [error, setError] = React.useState("");
  const [quitLoadingState, setQuitLoadingState] = React.useState(
    LOADING_STATE.NEVER
  );
  const handleQuit = () => {
    setError("");
    setQuitLoadingState(LOADING_STATE.LOADING);
    authApi
      .logout()
      .then((res) => setUser(null))
      .catch((e) => setError("Ошибка выхода"))
      .finally(() => setQuitLoadingState(LOADING_STATE.NEVER));
  };

  return (
    <Grid
      container
      item
      direction="column"
      alignItems="flex-end"
      md={4}
      xs={12}
    >
      <Typography>Ваша почта: {user.email}</Typography>

      <Typography> Ваше имя: {user.name}</Typography>
      <div>
        {user.role === ROLES.ADMIN && (
          <NavLink to="/registration">Регистрация</NavLink>
        )}
        <Button
          disabled={quitLoadingState === LOADING_STATE.LOADING}
          onClick={handleQuit}
          color="secondary"
        >
          Выйти
        </Button>
      </div>
      {error && <Typography> {error}</Typography>}
    </Grid>
  );
};

UserBox.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
};

export default UserBox;
